import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Button } from "reactstrap";

import blog1img from "./../Images/blog1.png";
import blog2img from "./../Images/blog2.png";
import styled from "styled-components";
import { FaRegClock } from "react-icons/fa6";
import { useParams } from "react-router-dom";

const divStyle = styled.div`
  display: flex;
`;

const PStyle = styled.p`
font-weight: "400",
font-family: "Mulish, sans-serif",font-style: "normal",
font-size: "16px",
`;

const H6Style = styled.h6`
font-weight: "600",
font-family: "Poppins, sans-serif",
font-style: "normal",
font-size: "20px"
`;

// Sample blog data

const gradientBackgroundStyle = {
  background: "linear-gradient(360deg,  #FFEADE 20%, #FCFCFC 100%)",
  height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const BlogPost = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <React.Fragment>
      <section
        className="section pb-0 hero-section"
        style={gradientBackgroundStyle}
      >
        <div className="bg-overlay bg-overlay-pattern"></div>
        <Container>
          <Row className="justify-content-center mb-5">
            <Col md={8} className="text-center">
              <h1
                className="fw-bold mb-3"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: isMobile ? "32px" : "64px",
                }}
              >
                Blog
              </h1>
              <p
                className="text-muted"
                style={{
                  fontWeight: "400",
                  fontFamily: "Mulish, sans-serif",
                  fontStyle: "normal",
                  fontSize: isMobile ? "14px" : "20px",
                }}
              >
                Empower Your Self-Learning Journey with a Wealth of Resources
                and Insights
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {id == 1 && (
        <Container fluid className="py-5">
          <Container>
            <Row>
              <div
                className="shadow-sm p-0"
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column-reverse" : "row",
                  alignItems: "center",
                  margin: "auto",
                  marginTop: "-180px",
                  justifyContent: "center",
                  borderRadius: "41px",
                  padding: "0",
                  backgroundColor: "#e6e4fa",

                  // width: "80%",
                  // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <div
                  style={{
                    // padding: "0",
                    background: "white",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: isMobile ? "41px" : "41px 0 0 41px",
                    padding: "40px",
                    backgroundColor: "#e6e4fa",
                    // width: "50%",
                  }}
                >
                  {/* <p
                  style={{
                    position: "relative",
                    top: 0,
                  }}
                >
                  New
                </p> */}
                  <div>
                    <h2
                      style={{
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                        fontSize: isMobile ? "24px" : "29px",
                      }}
                    >
                      Mastering Self-Learning: Tips to Successfully Learn a New
                      Skill on Your Own
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "15px",
                        color: "#121212",
                        fontWeight: "400",
                        fontFamily: "Mulish, sans-serif",
                        fontStyle: "normal",
                      }}
                    >
                      <FaRegClock />
                      <p className="m-0">5 mins Read</p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "80%",
                    borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                    height: isMobile ? "auto" : "400px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                      width: "100%",
                      height: "100%",
                    }}
                    src={blog1img}
                  />
                </div>
              </div>
              <div
                style={{
                  width: isMobile ? "98%" : "80%",
                  margin: "auto",
                  marginTop: "200px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                    fontSize: isMobile ? "24px" : "36px",
                  }}
                >
                  Mastering Self-Learning: Tips to Successfully Learn a New
                  Course on Your Own
                </h1>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Mulish, sans-serif",
                    fontStyle: "normal",
                    fontSize: "16px",
                  }}
                >
                  Created: 20th of Sept, 2024
                </p>
                <div className="my-5">
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    In today’s fast-paced world, traditional education isn't the
                    only path to career success. With the rise of online
                    resources and self-learning platforms, anyone with the
                    dedication and curiosity can master a new career or course
                    on their own terms. However, self-learning comes with its
                    own set of challenges—finding the right path, staying
                    motivated, and applying your knowledge in real-world
                    settings. In this blog, we'll share practical tips and
                    strategies to help you stay focused and thrive on your
                    self-learning journey.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    1. Set Clear Goals
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Before diving into any course or career, define your goals.
                    What do you want to achieve? Are you learning a new skill to
                    enhance your current job or pivot into a new industry?
                    Setting clear, specific goals will give you a roadmap to
                    follow and help you stay focused when challenges arise.{" "}
                    <br />
                    Tip: Break larger goals into smaller, actionable steps. For
                    example, if you want to learn web development, your goal for
                    the first month could be to learn the basics of HTML and
                    CSS.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    2. Choose the Right Resources
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    With so many resources available, it’s important to choose
                    ones that align with your learning style and goals. Look for
                    a combination of formats—videos, tutorials, textbooks,
                    interactive platforms—so that you can absorb information in
                    different ways. Websites like Coursera, Udemy, and
                    CurateLearn are great starting points for structured
                    learning paths. Tip: Read reviews and try out free versions
                    or demos of resources before committing. Find ones that
                    challenge you but don’t overwhelm you.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    3. Create a Study Schedule
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Consistency is key when it comes to self-learning. Unlike a
                    traditional classroom, there are no deadlines or exams
                    pushing you to keep up. Create a study schedule that fits
                    into your life, whether that’s dedicating an hour each day
                    or a few longer sessions on weekends. Tip: Treat your study
                    sessions like appointments—block out specific times on your
                    calendar to avoid distractions.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    4. Stay Organized
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Self-learning often involves juggling multiple
                    resources—online courses, textbooks, YouTube videos, and
                    more. Keep track of your progress by organizing your
                    materials. Use digital note-taking apps, bookmark helpful
                    links, and track your achievements in a way that motivates
                    you. Tip: Try using tools like Notion or Evernote to keep
                    everything in one place and easily accessible.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    5. Apply What You Learn
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Theoretical knowledge is important, but real growth comes
                    when you apply what you’ve learned. If you're learning a
                    technical skill like programming, build projects or
                    contribute to open-source platforms. If it's a business or
                    creative skill, find ways to incorporate it into your
                    current work or side projects. Tip: Share your work with a
                    community—whether it’s a forum, social media group, or a
                    mentor. This feedback will keep you accountable and help you
                    improve.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    6. Join a Community
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Self-learning doesn’t have to be a solitary experience. Join
                    online communities where you can ask questions, share
                    progress, and learn from others on a similar path. Platforms
                    like Reddit, Discord, or specialized learning platforms
                    often have forums or groups for various fields. Tip:
                    Engaging with others not only boosts motivation but also
                    exposes you to diverse perspectives and problem-solving
                    approaches.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    7. Embrace Mistakes and Stay Persistent
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Learning something new isn’t always easy, and you’re bound
                    to hit some roadblocks along the way. When things get tough,
                    remember that mistakes are part of the learning process.
                    Don’t be afraid to get things wrong; instead, use your
                    errors as learning opportunities. Tip: If you feel stuck,
                    take a short break and return with fresh energy. Persistence
                    is key—every small effort adds up!
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    8. Measure Your Progress
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    As you continue your learning journey, take time to reflect
                    on how far you’ve come. Whether it’s completing a course,
                    building a project, or understanding a concept that once
                    seemed impossible, these milestones keep you motivated and
                    remind you of your growth. Tip: Celebrate your wins, no
                    matter how small! Reward yourself for sticking to your
                    learning plan.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    9. Find a Mentor or Accountability Partner
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Even in self-learning, having someone to guide you or hold
                    you accountable can make a huge difference. A mentor with
                    experience in the field you're studying can provide valuable
                    insights and feedback, while an accountability partner can
                    help keep you on track by checking in on your progress. Tip:
                    Reach out to professionals in your field on LinkedIn or
                    networking communities—many are happy to share their
                    knowledge or offer guidance.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    10. Stay Curious and Keep Learning
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    The most successful self-learners never stop exploring new
                    ideas and opportunities to grow. Even after mastering your
                    chosen career or course, stay curious, continue expanding
                    your knowledge, and look for ways to apply it in different
                    contexts. Tip: Set long-term learning goals and always stay
                    updated on trends and developments in your field.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Final Thoughts
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Self-learning is an empowering journey that puts you in
                    control of your education and career development. With the
                    right mindset, goals, and resources, you can successfully
                    master any skill or career on your own. Remember, it’s not
                    about speed—it’s about consistency and progress. Now, go
                    ahead and take the first step toward mastering that new
                    skill or career you’ve been thinking about. Happy learning!
                    🎓🚀
                  </PStyle>
                </div>
              </div>
              <p
                style={{
                  marginTop: "24px",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  fontStyle: "normal",
                }}
              >
                Read more Blogs
              </p>
              <div
                className="shadow-sm"
                style={{
                  width: "500px",
                  backgroundColor: "white",
                  padding: 0,
                  borderRadius: "41px",
                  marginTop: "10px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    borderRadius: "41px",
                  }}
                  src={blog2img}
                />
                <div
                  style={{
                    padding: "40px",
                  }}
                >
                  <p
                    style={{
                      color: "#767676",
                      fontWeight: "700",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "14px",
                    }}
                  >
                    25th September, 2024
                  </p>
                  <h2
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: isMobile ? "16px" : "24px",
                    }}
                  >
                    Unlock Your Potential with CurateLearn: A Guide to
                    Successful Self-Learning
                  </h2>
                  <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/2`}
                    style={{
                      borderRadius: "50px",
                      background: "#FF7223",
                      color: "white",
                      borderColor: "#FF7223",
                      fontFamily: "Mulish, sans-serif",
                    }}
                  >
                    Read More
                  </Button>
                </div>
              </div>
              {/* <Col key={blogPosts[0].id} md={6} className="mb-4">
              <Card
                className="h-100 shadow-sm"
                style={{ borderRadius: "20px", overflow: "hidden" }}
              >
                <Card.Img
                  variant="top"
                  src={blogPosts[0].image}
                  style={{
                    borderRadius: "20px 20px 0 0",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <Card.Body className="d-flex flex-column">
                  <small className="text-muted mb-2">{blogPosts[0].date}</small>
                  <Card.Title className="fw-bold mb-3">
                    {blogPosts[0].title}
                  </Card.Title>
                  <Card.Text className="text-muted">
                    {blogPosts[0].preview}
                  </Card.Text> */}
              {/* <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/${post.id}`}
                    style={{ borderRadius: "50px" }}
                  >
                    Read More
                  </Button> */}
              {/* </Card.Body>
              </Card> */}
              {/* </Col>
            {blogPosts.map((post) => (
              <Col key={post.id} md={6} className="mb-4">
                <Card
                  className="h-100 shadow-sm"
                  style={{ borderRadius: "20px", overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={post.image}
                    style={{
                      borderRadius: "20px 20px 0 0",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <small className="text-muted mb-2">{post.date}</small>
                    <Card.Title className="fw-bold mb-3">
                      {post.title}
                    </Card.Title>
                    <Card.Text className="text-muted">{post.preview}</Card.Text> */}
              {/* <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/${post.id}`}
                    style={{ borderRadius: "50px" }}
                  >
                    Read More
                  </Button> */}
              {/* </Card.Body>
                </Card>
              </Col>
            ))} */}
            </Row>
          </Container>
        </Container>
      )}
      {id == 2 && (
        <Container fluid className="py-5">
          <Container>
            <Row>
              <div
                className="shadow-sm p-0"
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column-reverse" : "row",
                  alignItems: "center",
                  margin: "auto",
                  marginTop: "-180px",
                  justifyContent: "center",
                  borderRadius: "41px",
                  padding: "0",
                  backgroundColor: "#eef2fb",

                  // width: "80%",
                  // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <div
                  style={{
                    // padding: "0",
                    background: "white",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: isMobile ? "41px" : "41px 0 0 41px",
                    padding: "40px",
                    backgroundColor: "#eef2fb",
                    // width: "50%",
                  }}
                >
                  {/* <p
                  style={{
                    position: "relative",
                    top: 0,
                  }}
                >
                  New
                </p> */}
                  <div>
                    <h2
                      style={{
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                        fontSize: isMobile ? "24px" : "29px",
                      }}
                    >
                      Unlock Your Potential with CurateLearn: A Guide to
                      Successful Self-Learning
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "15px",
                        color: "#121212",
                        fontWeight: "400",
                        fontFamily: "Mulish, sans-serif",
                        fontStyle: "normal",
                      }}
                    >
                      <FaRegClock />
                      <p className="m-0">5 mins Read</p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "80%",
                    borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                    height: isMobile ? "auto" : "400px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                      width: "100%",
                      height: "100%",
                    }}
                    src={blog2img}
                  />
                </div>
              </div>
              <div
                style={{
                  width: isMobile ? "98%" : "80%",
                  margin: "auto",
                  marginTop: "200px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                    fontSize: isMobile ? "20px" : "36px",
                  }}
                >
                  Unlock Your Potential with CurateLearn: A Guide to Successful
                  Self-Learning
                </h1>
                <p
                  style={{
                    fontWeight: "700",
                    fontFamily: "Mulish, sans-serif",
                    fontStyle: "normal",
                    fontSize: "16px",
                  }}
                >
                  Created: 20th of Sept, 2024
                </p>
                <div className="my-5">
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Welcome to CurateLearn: Your Hub for Self-Learning and
                    Growth In today's fast-paced world, the ability to learn
                    independently is more important than ever. Self-learning not
                    only empowers individuals to adapt to changing job markets
                    but also opens doors to a wealth of knowledge and skills.
                    CurateLearn is designed to support you on this journey,
                    offering a platform that caters to your unique learning
                    needs and aspirations. With a range of resources at your
                    fingertips, you're equipped to take charge of your education
                    and shape your future. CurateLearn strongly advocates for
                    the power of self-learning to build careers and uncover new
                    opportunities. Whether you want to acquire new skills,
                    switch industries, or expand your knowledge base,
                    CurateLearn provides the tools, community, and support
                    necessary for your success. As a self-learner, you have full
                    control over your educational journey. CurateLearn enables
                    you to customize your learning pat with AI-Curated
                    resources, quizzes, and interactive tools tailored to your
                    specific goals. You have the flexibility to study at your
                    own pace and on your own schedule, ensuring that your
                    learning experience aligns with your lifestyle.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Set Your Learning Goals
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Before diving into any course or career, it's important to
                    define your goals. What do you want to achieve? Are you
                    learning a new skill to enhance your current job or pivot
                    into a new industry? Setting clear, specific goals will
                    provide you with a roadmap to follow and help you stay
                    focused when challenges arise. Tip: Break larger goals into
                    smaller, actionable steps. For example, if you want to learn
                    web development, your goal for the first month could be to
                    master the basics of HTML and CSS. You can achieve this
                    effectively by using CurateLearn, a AI Curated learning
                    platform where you can customize your learning path, access
                    targeted resources, and track your progress every step of
                    the way.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Signup on CurateLearn
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Signing up on CurateLearn is your first step toward
                    unlocking a world of knowledge and opportunities! Joining
                    our platform gives you access to a wealth of resources,
                    including courses, quizzes, and personalized learning paths
                    tailored to your interests and goals. The sign-up process is
                    quick and easy, allowing you to dive into your learning
                    journey in no time. Don’t wait—sign up here!
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Create a Learning Space with AI Curated Curriculum:
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    With CurateLearn, you can create personalized learning
                    spaces created by AI-curated curriculums tailored to your
                    specific interests and goals. Whether you're exploring a new
                    career, mastering a skill, or pursuing a hobby, the AI will
                    organize the best resources and design a custom learning
                    path just for you, making your journey more efficient and
                    focused on what matters most.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Create Your Own Library:
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    On CurateLearn, you can upload resources that directly
                    support your learning journey, whether you're advancing in a
                    new career, honing a specific skill, or exploring a personal
                    hobby. These resources will serve as valuable tools, helping
                    you stay focused and achieve your learning goals more
                    effectively.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Track Your Progress with Quizzes:
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    As you continue your learning journey, take time to reflect
                    on how far you’ve come by using quizzes to assess your
                    knowledge. Whether it’s completing a quiz after a course,
                    testing your understanding of a project, or mastering a
                    concept that once seemed difficult, these assessments will
                    keep you motivated and provide clear milestones to remind
                    you of your growth.
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Interact with Our AI Chat for Personalized Support:
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Our AI chat acts as your online mentor, offering
                    personalized guidance and feedback throughout your learning
                    journey. Whether you need help understanding a concept,
                    staying on track, or simply a nudge of encouragement, the AI
                    is here to support you, answer questions, and keep you
                    motivated as you progress
                  </PStyle>
                </div>
                <div className="my-5">
                  <h6
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: "20px",
                    }}
                  >
                    Join a community of fellow learners
                  </h6>
                  <PStyle
                    style={{
                      fontWeight: "400",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "16px",
                    }}
                  >
                    Become a part of our beta user group and join a vibrant
                    community of fellow learners eager to explore and test the
                    CurateLearn platform! By joining, you'll not only contribute
                    valuable feedback to help us refine and improve the
                    experience for everyone, but you'll also have the
                    opportunity to continue your own learning journey alongside
                    others who share your interests. Your insights will play a
                    crucial role in shaping the future of CurateLearn, and as a
                    beta user, you'll gain early access to new features and
                    resources. Don’t miss this chance to influence the platform
                    while enhancing your skills—join our beta group here!  Thank
                    you for exploring CurateLearn and considering joining our
                    vibrant community of learners! We believe that together, we
                    can create a dynamic environment that fosters growth and
                    knowledge-sharing. Your feedback and participation are
                    invaluable as we strive to enhance the learning experience
                    for everyone. As you embark on your self-learning journey
                    with us, remember that the possibilities are endless. Let’s
                    take this exciting journey together—sign up today, join our
                    beta group, and start shaping your future with CurateLearn!
                  </PStyle>
                </div>
              </div>
              <p
                style={{
                  marginTop: "24px",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  fontStyle: "normal",
                }}
              >
                Read more Blogs
              </p>
              <div
                className="shadow-sm"
                style={{
                  width: "500px",
                  backgroundColor: "white",
                  padding: 0,
                  borderRadius: "41px",
                  marginTop: "10px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    borderRadius: "41px",
                  }}
                  src={blog1img}
                />
                <div
                  style={{
                    padding: "40px",
                  }}
                >
                  <p
                    style={{
                      color: "#767676",
                      fontWeight: "700",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "14px",
                    }}
                  >
                    25th September, 2024
                  </p>
                  <h2
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: isMobile ? "16px" : "24px",
                    }}
                  >
                    Unlock Your Potential with CurateLearn: A Guide to
                    Successful Self-Learning
                  </h2>
                  <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/1`}
                    style={{
                      borderRadius: "50px",
                      background: "#FF7223",
                      color: "white",
                      borderColor: "#FF7223",
                      fontFamily: "Mulish, sans-serif",
                    }}
                  >
                    Read More
                  </Button>
                </div>
              </div>
              {/* <Col key={blogPosts[0].id} md={6} className="mb-4">
              <Card
                className="h-100 shadow-sm"
                style={{ borderRadius: "20px", overflow: "hidden" }}
              >
                <Card.Img
                  variant="top"
                  src={blogPosts[0].image}
                  style={{
                    borderRadius: "20px 20px 0 0",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <Card.Body className="d-flex flex-column">
                  <small className="text-muted mb-2">{blogPosts[0].date}</small>
                  <Card.Title className="fw-bold mb-3">
                    {blogPosts[0].title}
                  </Card.Title>
                  <Card.Text className="text-muted">
                    {blogPosts[0].preview}
                  </Card.Text> */}
              {/* <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/${post.id}`}
                    style={{ borderRadius: "50px" }}
                  >
                    Read More
                  </Button> */}
              {/* </Card.Body>
              </Card> */}
              {/* </Col>
            {blogPosts.map((post) => (
              <Col key={post.id} md={6} className="mb-4">
                <Card
                  className="h-100 shadow-sm"
                  style={{ borderRadius: "20px", overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={post.image}
                    style={{
                      borderRadius: "20px 20px 0 0",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <small className="text-muted mb-2">{post.date}</small>
                    <Card.Title className="fw-bold mb-3">
                      {post.title}
                    </Card.Title>
                    <Card.Text className="text-muted">{post.preview}</Card.Text> */}
              {/* <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/${post.id}`}
                    style={{ borderRadius: "50px" }}
                  >
                    Read More
                  </Button> */}
              {/* </Card.Body>
                </Card>
              </Col>
            ))} */}
            </Row>
          </Container>
        </Container>
      )}
    </React.Fragment>
  );
};

export default BlogPost;
