import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Button } from "reactstrap";
import img1 from "./../Images/blogimage.svg";
import img2 from "./../Images/blogimage2.svg";
import img3 from "./../Images/blogimage2.svg";
import img4 from "./../Images/blogimage3.svg";
import img5 from "./../Images/blogimage4.svg";
import img6 from "./../Images/blogimage5.svg";
import img7 from "./../Images/blogbanner.svg";
import blog1img from "./../Images/blog1.png";
import blog2img from "./../Images/blog2.png";
import styled from "styled-components";

const divStyle = styled.div`
  display: flex;
`;

// Sample blog data
export const blogPosts = [
  {
    id: 1,
    image: img1,
    date: "17th June 2024",
    title:
      "The Side Hustle Shuffle: Making Time for Your Passion Project with Ease",
    preview:
      "We've all been there. You scroll through social media and see everyone...",
  },
  {
    id: 2,
    image: img2,
    date: "23rd July 2024",
    title: "Mastering the Art of Self-Taught Programming: A Beginner's Guide",
    preview:
      "In the digital age, programming skills are more valuable than ever...",
  },
  {
    id: 3,
    image: img4,
    date: "5th August 2024",
    title:
      "From Novice to Expert: Charting Your Course in Self-Directed Learning",
    preview:
      "The journey of self-directed learning can be both exciting and daunting...",
  },
  {
    id: 4,
    image: img5,
    date: "19th September 2024",
    title:
      "The Power of Community in Online Learning: Building Your Support Network",
    preview:
      "While self-learning offers flexibility and autonomy, it doesn't mean you have to go it alone...",
  },
  {
    id: 5,
    image: img5,
    date: "2nd October 2024",
    title: "Balancing Act: Juggling Work, Life, and Continuous Learning",
    preview:
      "In today's fast-paced world, the pursuit of knowledge never stops...",
  },
  {
    id: 6,
    image: img7,
    date: "15th November 2024",
    title:
      "Tech Tools for Self-Learners: Maximizing Efficiency in Your Studies",
    preview:
      "The digital revolution has brought a wealth of tools to aid self-learners...",
  },
  {
    id: 7,
    image: img7,
    date: "7th December 2024",
    title:
      "The Psychology of Self-Learning: Understanding Your Mind for Better Results",
    preview:
      "Delving into the realm of self-learning isn't just about acquiring knowledge...",
  },
  {
    id: 8,
    image: img1,
    date: "20th January 2025",
    title: "Beyond Books: Unconventional Resources for the Modern Self-Learner",
    preview:
      "While traditional learning resources have their place, the modern self-learner has a world of options...",
  },
  {
    id: 9,
    image: img1,
    date: "3rd February 2025",
    title:
      "From Theory to Practice: Applying Self-Taught Skills in the Real World",
    preview:
      "One of the biggest challenges for self-learners is bridging the gap between theory and practice...",
  },
  {
    id: 10,
    image: img1,
    date: "18th March 2025",
    title:
      "The Future of Self-Learning: Trends and Predictions for the Next Decade",
    preview:
      "As technology continues to evolve at a rapid pace, so too does the landscape of self-learning...",
  },
];

const gradientBackgroundStyle = {
  background: "linear-gradient(360deg,  #FFEADE 20%, #FCFCFC 100%)",
  height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Blog = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <React.Fragment>
      <section
        className="section pb-0 hero-section"
        style={gradientBackgroundStyle}
      >
        <div className="bg-overlay bg-overlay-pattern"></div>
        <Container>
          <Row className="justify-content-center mb-5">
            <Col md={8} className="text-center">
              <h1
                className="fw-bold mb-3"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: isMobile ? "32px" : "64px",
                }}
              >
                Blog
              </h1>
              <p
                className="text-muted"
                style={{
                  fontWeight: "400",
                  fontFamily: "Mulish, sans-serif",
                  fontStyle: "normal",
                  fontSize: isMobile ? "14px" : "20px",
                }}
              >
                Empower Your Self-Learning Journey with a Wealth of Resources
                and Insights
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid className="py-5">
        <Container>
          <Row>
            <div
              className="shadow-sm p-0"
              style={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                alignItems: "center",
                marginTop: "-180px",
                justifyContent: "center",
                borderRadius: "41px",
                padding: "0",
                // margin: "auto",

                // width: "80%",
                // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
              }}
            >
              <div
                style={{
                  // padding: "0",
                  background: "white",
                  height: "100%",
                  display: "flex",
                  alignItems: "end",
                  borderRadius: isMobile ? "41px" : "41px 0 0 41px",
                  padding: "40px",
                  // width: "50%",
                }}
              >
                {/* <p
                  style={{
                    position: "relative",
                    top: 0,
                  }}
                >
                  New
                </p> */}
                <div>
                  <p
                    style={{
                      color: "#767676",
                      fontWeight: "700",
                      fontFamily: "Mulish, sans-serif",
                      fontStyle: "normal",
                      fontSize: "14px",
                    }}
                  >
                    24th September, 2024
                  </p>
                  <h2
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "normal",
                      fontSize: isMobile ? "16px" : "24px",
                    }}
                  >
                    Mastering Self-Learning: Tips to Successfully Learn a New
                    Skill on Your Own
                  </h2>
                  <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/1`}
                    style={{
                      borderRadius: "50px",
                      background: "#FF7223",
                      color: "white",
                      borderColor: "#FF7223",
                      fontFamily: "Mulish, sans-serif",
                    }}
                  >
                    Read More
                  </Button>
                </div>
              </div>
              <div
                style={{
                  width: isMobile ? "100%" : "80%",
                  borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                  height: isMobile ? "auto" : "400px",
                }}
              >
                <img
                  style={{
                    borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                    width: "100%",
                    height: isMobile ? "auto" : "100%",
                    padding: "0",
                  }}
                  src={blog1img}
                />
              </div>
            </div>
            <div
              className="shadow-sm"
              style={{
                width: "500px",
                backgroundColor: "white",
                padding: 0,
                borderRadius: "41px",
                marginTop: "100px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderRadius: "41px",
                }}
                src={blog2img}
              />
              <div
                style={{
                  padding: "40px",
                }}
              >
                <p
                  style={{
                    color: "#767676",
                    fontWeight: "700",
                    fontFamily: "Mulish, sans-serif",
                    fontStyle: "normal",
                    fontSize: "14px",
                  }}
                >
                  25th September, 2024
                </p>
                <h2
                  style={{
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                    fontSize: isMobile ? "16px" : "24px",
                  }}
                >
                  Unlock Your Potential with CurateLearn: A Guide to Successful
                  Self-Learning
                </h2>
                <Button
                  color="primary"
                  outline
                  className="mt-auto align-self-start"
                  href={`/blog/2`}
                  style={{
                    borderRadius: "50px",
                    background: "#FF7223",
                    color: "white",
                    borderColor: "#FF7223",
                    fontFamily: "Mulish, sans-serif",
                  }}
                >
                  Read More
                </Button>
              </div>
            </div>
            {/* <Col key={blogPosts[0].id} md={6} className="mb-4">
              <Card
                className="h-100 shadow-sm"
                style={{ borderRadius: "20px", overflow: "hidden" }}
              >
                <Card.Img
                  variant="top"
                  src={blogPosts[0].image}
                  style={{
                    borderRadius: "20px 20px 0 0",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <Card.Body className="d-flex flex-column">
                  <small className="text-muted mb-2">{blogPosts[0].date}</small>
                  <Card.Title className="fw-bold mb-3">
                    {blogPosts[0].title}
                  </Card.Title>
                  <Card.Text className="text-muted">
                    {blogPosts[0].preview}
                  </Card.Text> */}
            {/* <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/${post.id}`}
                    style={{ borderRadius: "50px" }}
                  >
                    Read More
                  </Button> */}
            {/* </Card.Body>
              </Card> */}
            {/* </Col>
            {blogPosts.map((post) => (
              <Col key={post.id} md={6} className="mb-4">
                <Card
                  className="h-100 shadow-sm"
                  style={{ borderRadius: "20px", overflow: "hidden" }}
                >
                  <Card.Img
                    variant="top"
                    src={post.image}
                    style={{
                      borderRadius: "20px 20px 0 0",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <small className="text-muted mb-2">{post.date}</small>
                    <Card.Title className="fw-bold mb-3">
                      {post.title}
                    </Card.Title>
                    <Card.Text className="text-muted">{post.preview}</Card.Text> */}
            {/* <Button
                    color="primary"
                    outline
                    className="mt-auto align-self-start"
                    href={`/blog/${post.id}`}
                    style={{ borderRadius: "50px" }}
                  >
                    Read More
                  </Button> */}
            {/* </Card.Body>
                </Card>
              </Col>
            ))} */}
          </Row>
        </Container>
      </Container>
      {/* </section> */}
    </React.Fragment>
  );
};

export default Blog;
